<template>
  <v-menu
  origin="center center"
  transition="scale-transition"
  bottom
  left
  content-class="myclass"
  >
    <v-icon color="primary" dark slot="activator">
        person
    </v-icon>
    <v-list dark class="primary">
      <v-list-tile>
        <v-list-tile-title>{{ getUser.userId }}</v-list-tile-title>
      </v-list-tile>

      <v-divider />

      <v-list-tile @click="passDialog = !passDialog">
        <v-list-tile-title>Password</v-list-tile-title>
      </v-list-tile>
      <v-list-tile @click="logoutClick">
        <v-list-tile-title>Logout</v-list-tile-title>
      </v-list-tile>
    </v-list>
    <ChangePasswordDialog
      :open="passDialog"
      @closed="passDialog = false"
      @save="savePassword"
    />
  </v-menu>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import credUserService from '@/services/CredUserService';
import CredUser from '@/models/CredUser';

export default {
  name: 'TheToolbarUserIcon',
  data: () => ({
    passDialog: false,
  }),
  computed: {
    ...mapGetters('app', ['getUser']),
  },
  components: {
    ChangePasswordDialog: () => import('@/components/dialogs/ChangePasswordDialog'),
  },
  methods: {
    ...mapActions('login', ['logout']),
    logoutClick() {
      this.logout();
      this.$router.push({ name: 'Login' });
    },
    async savePassword(pass) {
      this.passDialog = false;
      const updatedUser = new CredUser(this.getUser);
      updatedUser.password = pass;
      const resp = await credUserService.updatePassword(updatedUser);
      if (resp.status === 200) {
        this.logout();
      }
    },
  },
};
</script>
